import * as i0 from '@angular/core';
import { makeEnvironmentProviders, Injectable } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS } from '@angular/material/button-toggle';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { NativeDateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import dayjs from 'dayjs';
import customFormat from 'dayjs/plugin/customParseFormat';
const MATERIAL_DEFAULT_OPTIONS = [{
  provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
  useValue: {
    appearance: 'outline'
  }
}, {
  provide: MAT_TABS_CONFIG,
  useValue: {
    animationDuration: '0ms'
  }
}, {
  provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
  useValue: {
    hideSingleSelectionIndicator: true,
    hideMultipleSelectionIndicator: true
  }
}, {
  provide: MAT_DIALOG_DEFAULT_OPTIONS,
  useValue: {
    autoFocus: false,
    disableClose: true,
    maxHeight: '90vh'
  }
}];
function provideMaterialDefaults() {
  return makeEnvironmentProviders(MATERIAL_DEFAULT_OPTIONS);
}
dayjs.extend(customFormat);
class CustomDateAdapter extends NativeDateAdapter {
  parse(value, parseFormat) {
    const date = dayjs(value, parseFormat);
    if (!date.isValid()) {
      return null;
    }
    return date.toDate();
  }
  format(date, displayFormat) {
    return dayjs(date).format(displayFormat);
  }
  static ɵfac = /* @__PURE__ */(() => {
    let ɵCustomDateAdapter_BaseFactory;
    return function CustomDateAdapter_Factory(__ngFactoryType__) {
      return (ɵCustomDateAdapter_BaseFactory || (ɵCustomDateAdapter_BaseFactory = i0.ɵɵgetInheritedFactory(CustomDateAdapter)))(__ngFactoryType__ || CustomDateAdapter);
    };
  })();
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CustomDateAdapter,
    factory: CustomDateAdapter.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomDateAdapter, [{
    type: Injectable
  }], null, null);
})();
const MAT_DAYJS_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY'
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthLabel: 'MMM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
class CustomDateFormat {
  _parse = {
    dateInput: 'DD/MM/YYYY'
  };
  _display = {
    dateInput: 'DD/MM/YYYY',
    monthLabel: 'MMM',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  };
  get parse() {
    return this._parse;
  }
  get display() {
    return this._display;
  }
  set parse(parse) {
    this._parse = Object.assign({}, this._parse, parse);
  }
  set display(display) {
    this._display = Object.assign({}, this._display, display);
  }
  updateDateFormat(parse, display) {
    this.parse = parse;
    if (!display) {
      display = parse;
    }
    this.display = display;
  }
  static ɵfac = function CustomDateFormat_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CustomDateFormat)();
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: CustomDateFormat,
    factory: CustomDateFormat.ɵfac
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomDateFormat, [{
    type: Injectable
  }], null, null);
})();
function provideDateAdapter(locale = 'en-GB') {
  return makeEnvironmentProviders([{
    provide: MAT_DATE_LOCALE,
    useValue: locale
  }, {
    provide: MAT_DATE_FORMATS,
    useValue: MAT_DAYJS_FORMATS
  }, {
    provide: DateAdapter,
    useClass: CustomDateAdapter,
    deps: [MAT_DATE_LOCALE]
  }]);
}

/**
 * Generated bundle index. Do not edit.
 */

export { CustomDateAdapter, CustomDateFormat, MAT_DAYJS_FORMATS, provideDateAdapter, provideMaterialDefaults };

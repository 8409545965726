import { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  pwa: false,
  gtmId: 'GTM-TMT8SVDN',
  apiEndpoint: 'https://app-globaldashboard-api-dev.azurewebsites.net',
  auth0: {
    domain: 'auth-dev.tnssng.com',
    clientId: 'zPkI1cmum4JxLT3Le2vgZbR2b4ADzqWw',
    authorizationParams: {
      redirect_uri: `${window.location.origin}/callback`,
      audience: 'https://app-globaldashboard-api-dev.azurewebsites.net',
    },
    errorPath: '/error',
    httpInterceptor: {
      allowedList: ['https://app-globaldashboard-api-dev.azurewebsites.net/*'],
    },
  },
};

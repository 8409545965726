import * as i0 from '@angular/core';
import { Directive, Input, HostBinding, Inject, Optional, inject } from '@angular/core';
import { MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import * as i2 from '@acacium-group/ui/providers';
import { CustomDateAdapter, CustomDateFormat } from '@acacium-group/ui/providers';
import * as i1 from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
class AgThemeDirective {
  agTheme = 'primary';
  static ɵfac = function AgThemeDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AgThemeDirective)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: AgThemeDirective,
    selectors: [["", "agTheme", ""]],
    hostVars: 2,
    hostBindings: function AgThemeDirective_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassMap(ctx.agTheme);
      }
    },
    inputs: {
      agTheme: [2, "agTheme", "agTheme", value => value || 'primary']
    },
    standalone: true,
    features: [i0.ɵɵInputTransformsFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AgThemeDirective, [{
    type: Directive,
    args: [{
      selector: `[agTheme]`,
      standalone: true
    }]
  }], null, {
    agTheme: [{
      type: Input,
      args: [{
        transform: value => value || 'primary'
      }]
    }, {
      type: HostBinding,
      args: ['class']
    }]
  });
})();
class DateFormatDirective {
  matDateFormat;
  _ngControl;
  configDateParse;
  configDateDisplay;
  constructor(matDateFormat, _ngControl) {
    this.matDateFormat = matDateFormat;
    this._ngControl = _ngControl;
  }
  set datePickerFormat(format) {
    if (this.configDateParse) {
      this.matDateFormat.updateDateFormat(this.configDateParse, this.configDateDisplay);
    } else {
      this.matDateFormat.updateDateFormat({
        dateInput: format
      });
    }
    if (this._ngControl) {
      const value = this._ngControl.value;
      this._ngControl.valueAccessor?.writeValue(value);
    }
  }
  static ɵfac = function DateFormatDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DateFormatDirective)(i0.ɵɵdirectiveInject(MAT_DATE_FORMATS), i0.ɵɵdirectiveInject(i1.NgControl, 8));
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: DateFormatDirective,
    selectors: [["", "dateFormat", ""]],
    inputs: {
      configDateParse: "configDateParse",
      configDateDisplay: "configDateDisplay",
      datePickerFormat: [0, "dateFormat", "datePickerFormat"]
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([{
      provide: DateAdapter,
      useClass: CustomDateAdapter
    }, {
      provide: MAT_DATE_FORMATS,
      useClass: CustomDateFormat
    }])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateFormatDirective, [{
    type: Directive,
    args: [{
      selector: '[dateFormat]',
      standalone: true,
      providers: [{
        provide: DateAdapter,
        useClass: CustomDateAdapter
      }, {
        provide: MAT_DATE_FORMATS,
        useClass: CustomDateFormat
      }]
    }]
  }], () => [{
    type: i2.CustomDateFormat,
    decorators: [{
      type: Inject,
      args: [MAT_DATE_FORMATS]
    }]
  }, {
    type: i1.NgControl,
    decorators: [{
      type: Optional
    }]
  }], {
    configDateParse: [{
      type: Input
    }],
    configDateDisplay: [{
      type: Input
    }],
    datePickerFormat: [{
      type: Input,
      args: ['dateFormat']
    }]
  });
})();
class MonthPickerDirective {
  _datepicker = inject(MatDatepicker);
  constructor() {
    if (this._datepicker) {
      this._datepicker.startView = 'year';
      this._datepicker.monthSelected.pipe(takeUntilDestroyed()).subscribe(date => {
        this._datepicker.select(date);
        this._datepicker.close();
      });
    }
  }
  static ɵfac = function MonthPickerDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MonthPickerDirective)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: MonthPickerDirective,
    selectors: [["mat-datepicker", "monthPicker", ""]],
    standalone: true
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MonthPickerDirective, [{
    type: Directive,
    args: [{
      selector: 'mat-datepicker[monthPicker]',
      standalone: true
    }]
  }], () => [], null);
})();
class IconOnlyDirective {
  iconOnly = true;
  static ɵfac = function IconOnlyDirective_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || IconOnlyDirective)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: IconOnlyDirective,
    selectors: [["button", "iconOnly", ""]],
    hostVars: 2,
    hostBindings: function IconOnlyDirective_HostBindings(rf, ctx) {
      if (rf & 2) {
        i0.ɵɵclassProp("icon-only", ctx.iconOnly);
      }
    },
    inputs: {
      iconOnly: [2, "iconOnly", "iconOnly", value => coerceBooleanProperty(value)]
    },
    standalone: true,
    features: [i0.ɵɵInputTransformsFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconOnlyDirective, [{
    type: Directive,
    args: [{
      selector: 'button[iconOnly]',
      standalone: true
    }]
  }], null, {
    iconOnly: [{
      type: Input,
      args: [{
        transform: value => coerceBooleanProperty(value)
      }]
    }, {
      type: HostBinding,
      args: ['class.icon-only']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AgThemeDirective, DateFormatDirective, IconOnlyDirective, MonthPickerDirective };
